import React from "react";
import Nav from "../../components/nav";
import ErrorBoundary from "../../components/ErrorBoundary";
import MindBodyWidget from "../../components/mindbody";
import Layout from '../../components/layout-default'
import "./index.scss"
import Breadcrumbs from "../../components/breadcrumbs";
const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "Summar Special",
    link: "summar-special",
  },
];
const SummarSpecial = () => {
  return (
    <>
     <Layout
        title="Embrace Summer Vibes with Salsa & Bachata Classes | RF Dance"
        description="Perfect for Beginners, No Partner Needed Just Bring Yourself and Dance"
        pathname="classes/bachata-dance-classes-in-orange-county-ca"
        className="services_page"
      >
      <Nav />
      <div className="container">
        <Breadcrumbs crumbs={crumbs} />
        <div className="summar-vibe-section">
          <h1>Embrace Summer Vibes with Salsa & Bachata Classes</h1>
          <h3>$29 unlimited classes for one week</h3>
          <p>
            Perfect for Beginners, No Partner Needed – Just Bring Yourself and
            Dance
          </p>
          <div className="redeem-btn"><a href="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=41&sTG=22&prodId=10234">REDEEM</a></div>
        </div>
        <div id="mindbodywidget_custom">
          <ErrorBoundary>
            <MindBodyWidget
              id="mb-script-02"
              type="schedules"
              widgetPartner="object"
              widgetId="d48517709b0"
              widgetVersion="1"
            />
          </ErrorBoundary>
        </div>
      </div>
      </Layout>
    </>
  );
};

export default SummarSpecial;
